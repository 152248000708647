<template>
  <div class="loader-container" v-if="isLoading">
    <span class="loader"></span>
  </div>
  <apexchart
    v-else
    type="line"
    height="450"
    :options="costsResults.costsOptions"
    :series="costsResults.series"
  ></apexchart>
</template>

<script setup>
import { ref, defineProps, onMounted, watch } from "vue";
import { formatCurrencyWithoutSymbol, getMonth } from "@/mixins";
import { ManagerDashboardController } from "@/AppClubCarby/Controller/DashBoardsController";

const isLoading = ref(true);

const props = defineProps({
  managerId: {
    type: Number,
    required: true,
  },
  periodStart: {
    type: String,
    required: true,
  },
  periodEnd: {
    type: String,
    required: true,
  }, 
  gasStationId: {
    type: Number,
    required: true,
  },
});


const managerDashboardService = new ManagerDashboardController(
  props.managerId,
  props.periodStart,
  props.periodEnd,
  props.gasStationId
);

const chartData = ref([]);

const costsResults = ref({
  series: [
    {
      name: "Custo",
      type: "column",
      data: [],
      color: "#11592E",
    },
  ],
  costsOptions: {
    chart: {
      type: "line",
      stacked: true,
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [0, 1, 2, 3],
      formatter: (val) => {
        return formatCurrencyWithoutSymbol(val);
      },
      background: {
        foreColor: "white",
        borderWidth: 0,
        padding: 0,
      },
      style: {
        colors: ["black"],
        fontSize: "12px",
      },
    },
    labels: [],
    yaxis: [
      {
        title: {
          text: "Custo (R$)",
          rotate: -90,
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: "16px",
          },
        },
        forceNiceScale: true,
        decimalsInFloat: 0,
      },
    ],
    plotOptions: {
      bar: {
        dataLabels: {
          position: "center",
        },
      },
    },
  },
  set setLabels(data) {
    console.log(data)
    this.costsOptions.labels = data;
  },
  setSeries(index, data) {
    this.series[index].data = data;
  },
});

const updateCostsChart = () => {
  const costsTotal = chartData.value.map((data) => {
    return data.monthlyFee;
  });

  const costsMonth = chartData.value.map((data) => {
    const dateStr = data.createdAt.split("-");
    console.log(getMonth("Full", dateStr[1]))
    return getMonth("Full", dateStr[1]);
  });

  costsResults.value.setSeries(0, costsTotal);

  costsResults.value.setLabels = costsMonth.filter((el, pos) => {
    return costsMonth.indexOf(el) == pos;
  });
};

onMounted(async () => {
  getData();
});

const getData = async () => {
  chartData.value = await managerDashboardService.costsData();
  console.log('chartData', chartData.value)
  updateCostsChart();
  isLoading.value = false
};

watch(props, (newVal, oldVal) => {
  if (newVal.toString() != oldVal.toString()) {
    getData();
  }
});
</script>

<style scoped>
.loader-container{
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}
.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: #11592E;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}
</style>
